<template>
  <div class="container-panel">
    <a-row class="header-panel">
      <div class="header-panel-item">
        <div class="logo"/>
<!--        <div class="title">广西港口精细化天气预报平台</div>-->
        <div class="menu-point-panel">
          <ul>
            <a-space :size="size">
              <li v-for="item in menuList" :key="item.path" @click="goto(item)"
                  :class="['top-menu-list', active===item.value ? 'isActive' : 'menu-item']">
                {{ item.label }}
              </li>
            </a-space>
          </ul>
        </div>
        <div class="user-info-panel">
          <span>欢迎您，{{ nickname }}</span>
          <logout-outlined style="cursor: pointer;" @click="handleOut"/>
        </div>
      </div>
    </a-row>
    <a-row class="center-panel">
      <router-view v-slot="{Component}">
        <component :is="Component"></component>
      </router-view>
    </a-row>
    <a-row class="footer-panel">
<!--      <span>青岛天洋气象科技 ©2022</span>-->
      <a href="https://beian.miit.gov.cn/" target="_blank">
        备案号：鲁ICP备2022035685号
      </a>
    </a-row>
  </div>
</template>

<script>
import {createVNode, defineComponent, provide, ref} from 'vue';
import {menuList} from '@/config/base.config'
import {useRouter} from 'vue-router';
import store from "@/store";
import {ExclamationCircleOutlined, LogoutOutlined} from '@ant-design/icons-vue';
import {message, Modal} from "ant-design-vue";
import {logout} from "@/request/api";

export default defineComponent({
  components: {
    LogoutOutlined
  },
  setup() {
    const router = useRouter()

    function goto(item) {
      router.push(item.path)
      this.active = item.value
    }

    provide('store', store)

    const username = JSON.parse(localStorage.getItem('userInfo')).username
    const nickname = JSON.parse(localStorage.getItem('userInfo')).nickname

    const handleOut = () => {
      Modal.confirm({
        title: '退出？',
        icon: createVNode(ExclamationCircleOutlined),
        content: '是否确认退出当前系统？',
        okText: '退出',
        okType: 'danger',
        cancelText: '取消',
        async onOk() {
          try {
            const obj = {'username': username};
            const response = await logout(obj);
            const {code} = response;

            if (code === 1000) {
              message.success('已成功退出当前系统');
              store.commit("clearToken");
              window.location.reload();
              // await router.replace('/login');
            } else {
              message.error('退出失败，请重试');
            }
          } catch (error) {
            message.error('网络错误或服务器异常');
            console.error('退出错误:', error);
          }
        },
      });
    };

    return {
      menuList: ref(menuList),
      size: ref(8),
      active: ref('forecast'),
      goto,
      username,
      nickname,
      handleOut,
      router
    }
  }
});

</script>

<style scoped lang="scss">

.container-panel {
  width: 100vw;
  height: 100vh;
  background: #ffffff;

  .header-panel-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 0 0 20px;
    background: #141414;

    .logo {
      float: left;
      width: 120px;
      height: 31px;
      margin: 16px 24px 16px 0;
      background: url('@/assets/logo-2.png') no-repeat;
      background-size: 100% 100%;
    }

    .title {
      font-size: 22px;
      padding: 20px 0;
      color: #fbfbfc;
      font-weight: 700;
      font-family: system-ui, -apple-system, BlinkMacSystemFont, segoe ui, Roboto, Helvetica, Arial, sans-serif;
    }

    .menu-point-panel {
      display: flex;
      justify-content: space-between;
      flex: 1;
      height: 100%;
      align-items: center;
      padding: 0 20px;

      .top-menu-list {
        width: 100px;
        height: 100%;
        align-items: center;
        padding-top: 10px;
        cursor: pointer;
      }

      .isActive {
        color: #17f1df;
      }

      .menu-item {
        color: blue;
      }
    }

    .user-info-panel {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 300px;
      height: 100%;
      font-size: 18px;
      text-align: right;

      > span {
        color: #ffffff;
        padding-right: 15px;
      }
    }
  }

  .center-panel {
    width: 100%;
    //height: calc(100vh - 160px);
    overflow-y: auto;
    overflow-x: hidden;
  }

  .footer-panel {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    bottom: 5px;
    gap: 30px;
    width: 100%;
    height: 30px;
  }
}
</style>
